import React from "react";
import { graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01";
import Header from "@solid-ui-blocks/Header/Block01";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block02";
import FeatureTwo from "@solid-ui-blocks/FeaturesWithPhoto/Block05";
import WhyChooseUs from "@solid-ui-blocks/Features/Block04";
// import FeatureTwo from "@solid-ui-blocks/FeaturesWithPhoto/Block05";
import GetStarted from "@solid-ui-blocks/CallToAction/Block01";
import Footer from "@solid-ui-blocks/Footer/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import theme from "./_theme";
import styles from "./_styles";
import Tabs from "@solid-ui-components/Tabs/Tabs";
import FeatureThree from "@solid-ui-blocks/FeaturesWithPhoto/Block01/Block01";
import ContentText from "@solid-ui-components/ContentText";
import Features from "@solid-ui-blocks/Features/Block05/Block05";
import Hero from "@solid-ui-blocks/Hero/Block02";

// import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block01";
// import WhyChooseUs from "@solid-ui-blocks/Features/Block01";

const IndexPage = (props) => {
  const { allBlockContent } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  return (
    <Layout theme={theme} {...props}>
      <Seo title="Home" />
      {/* Modals */}
      <ModalWithTabs content={content["contact"]} />
      {/* Blocks */}
      <Header content={content["header"]} />
      <Divider space="5" />
      <Divider space="5" />
      <Container variant="full" sx={styles.heroContainer}>
        <Hero content={content["hero"]} />
      </Container>
      <Divider space="5" />
      <WhyChooseUs content={content["why-choose-us"]} />
      <Divider space="4" />
      <Container variant="wide" sx={styles.tabsContainer} id={"partnership"}>
        <div style={{ textAlign: "center" }}>
          <ContentText content={content["partnership-model"].text} />
        </div>
        <Divider space="4" />
        <Tabs space={5}>
          <FeatureOne content={content["tab-feature-one"]} />
          <FeatureThree content={content["tab-feature-three"]} />
          <FeatureTwo content={content["tab-feature-two"]} />
        </Tabs>
      </Container>
      <Divider space="6" />
      <Divider space="6" />
      <Divider space="6" />
      <Container variant="full" sx={styles.strategiesContainer}>
        <Divider space={-6} />
        <Divider space={-6} />
        <Container id={"services"}>
          <ContentText
            content={content["services"].text}
            sx={{ textAlign: "center" }}
          />
          <Features content={content["features"]} />
        </Container>
      </Container>
      <Divider space="5" />
      <Divider space="5" />
      <Container sx={styles.getStartedContainer}>
        <GetStarted content={content["get-started"]} />
      </Container>
      <Divider space="5" />
      <Footer content={content["footer"]} />
    </Layout>
  );
};

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
export default IndexPage;
